import React from "react"
import PrimaryNav from "./primarynav.js"

const Header = () => {
	return (
		<PrimaryNav />
	)
}

export default Header
